/** @jsx jsx */
import { jsx, Grid, Flex, Button, Styled, Container } from "theme-ui"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Song from "../components/song"
import Img from "gatsby-image"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Pianominiatyyrejä lapsille" />
    <Grid
      sx={{
        gridTemplateColumns: [null, null, null, "1fr 1fr"],
        gridGap: [null, null, null, 5],
        alignItems: "center",
        height: ["auto", null, null, "calc(100vh - 98px)"],
        maxHeight: ["auto", null, null, 800],
        mt: [60, 98],
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          order: 1,
          zIndex: 1,
          maxWidth: ["auto", null, 760, 640],
          px: 4,
          mb: 4,
          mr: "auto",
          mx: ["auto", null, null, 0],
        }}
      >
        <Styled.h1
          sx={{
            mt: [0, 4],
            mb: 3,
            fontSize: ["9vw", 5, 6, 7],
          }}
        >
          Kahdeksan vuodenaikaa ja muita pianokappaleita
        </Styled.h1>
        <Styled.p
          sx={{
            fontSize: [2, 3],
          }}
        >
          Tiesitkö, että Lapissa on kahdeksan vuodenaikaa? Kahdeksan vuodenaikaa
          ja muita pianokappaleita -pianominiatyyrikokoelman teemana on
          pohjoinen luonto ja sen vaihtuvat värit. Sävellykset ovat pieneen
          käteen sopivia lyhyitä pianokappaleita, joista monet ovat
          yksinkertaisempia kuin kuulokuvan perusteella voisi luulla.
          Kappaleissa on selkeät pedagogiset tehtävät sekä tulkinnallisesti että
          soittoteknisesti. Sävellysten tyylilaji on elokuvamusiikkityylin ja
          taidemusiikin välimaastossa – sävelkieli, josta monet lapset
          nauttivat. Lähes kaikissa kappaleissa pedaali on tärkeä
          sointivärielementti, ja monet kappaleista on helppo oppia muistamaan
          myös koskettimista suunnistaen.
        </Styled.p>
        <Grid
          sx={{
            mt: 3,
            gridGap: 2,
            gridTemplateColumns: [null, "repeat(2, minmax(auto, max-content))"],
          }}
        >
          <Button
            as="a"
            href="https://sulasol.fi/nuottikauppa/kahdeksan-vuodenaikaa-ja-muita-pianokappaleita/"
          >
            Osta verkkokaupasta
          </Button>
          <Button variant="default" as="a" href="https://youtu.be/zdlUGHeasf8">
            Katso esittelyvideo
          </Button>
        </Grid>
      </Flex>
      <Flex
        sx={{
          px: [3, null, null, 0],
          justifyContent: ["center", null, null, "flex-end"],
          height: ["auto", null, null, "100%"],
        }}
      >
        <Img
          sizes={{
            ...data.kahdeksanVuodenaikaa.childImageSharp.fluid,
          }}
          imgStyle={{ objectPosition: "center right" }}
          sx={{
            width: ["100%", "80%", "100%"],
            maxWidth: [640, null, null, 800],
            order: 0,
            zIndex: 0,
          }}
        />
      </Flex>
    </Grid>

    <Container variant="narrow" sx={{ px: [4, 0], mt: [3, null, 4] }}>
      <Song
        title="Kaamos"
        description="Napapiirin tienoilla päivänvaloa on pimeimpään vuodenaikaan niin niukasti, että aamu- ja iltarusko sekoittuvat toisiinsa. Kaamosaika ei ole pelkkää pimeyttä, vaan myös taivaanrannan maagista, pehmeän sinistä tai sinipunertavaa valoa, joka heijastuu valkoisesta lumipeitteestä. Kaamos-kappaleen salaperäiset ja jännittävät harmoniat kuvaavat hämärän hetkeä ja luonnon
      hiljaisuutta. Koskettimia käytetään laajalta alueelta ja
      kappaleessa voi harjoitella pehmeää soinnutusta ja melodian
      kirkkautta."
        image={data.kaamos}
      />
      <Song
        title="Pakkastalvi"
        description="Iloisesti hyppelevä Pakkastalvi kuvaa lasten leikkiä ulkona
            kirpeässä pakkassäässä. Sävellys on A-B-A-muotoinen, ja sen A-osan
            staccatot pistävät soittajan sormet napakasti töihin. Tunnelmaltaan
            vastakohtaisessa keskiosassa rauhoitutaan lämpimän takkatulen
            äärellä. Tai ehkäpä kappaleen keskiosan hitaampi syke kuvaa
            talvipesässään kuorsaavaa karhua? Kappaleessa voi harjoitella
            täsmäpedaalia, tai se on mahdollista soittaa myös kokonaan ilman
            pedaalia. Nuottikuva on helppo hahmottaa. Muinaisen kuuloiset
            harmoniat ovat kiinnostavasti duurin ja mollin välimaastossa ja
            kappaleessa harjoitellaan tempon ja dynamiikan vaihtelua."
        image={data.pakkastalvi}
      />
      <Song
        title="Hankikantokevät"
        description="
            Lapin kevättalven hohtavat hanget kutsuvat ulkoilemaan ja nauttimaan
            auringosta maalis-huhtikuussa. Hankikantokevät on rauhallinen ja
            onnellisen tunnelman pedaalikappale, jonka tempo voi olla erittäin
            hidas - lähestulkoon haukotteleva, aivan kuin kevätväsymystä olisi
            ilmassa. Vasemman käden rauhallisina puolinuotteina etenevä säestys
            on kuin kantavaa hankea, jonka päällä oikean käden häikäisevää
            auringonvaloa kuvaava melodia kimmeltää. Sävellaji on helppolukuinen
            G-duuri, ja kappaleessa on hyvä tilaisuus harjoitella muun muassa
            melodian ja säestyksen balansointia sekä sointuketjun hahmottamista
            f-viivastolla.
          "
        image={data.hankikanto}
      />
      <Song
        title="Jäidenlähtökevät"
        description="
            Nopea, synkopoiva kuudestoistaosanuoteissa kulkeva melodia kuvaa
            jäistä vapautuvaa jokea. Kevään riemu välittyy iloisesti virtaavassa
            musiikissa. Vasemmalla kädellä on albertinbassosäestys ja helppo
            sävellaji, F-duuri, tekee nuotinluvusta kohtalaisen helppoa.
            Peukaloiden ja käsien hyvin kannateltu asento auttaa kauniin
            lopputuloksen saavuttamisessa, ja kappaleen musiikillinen muoto on
            selkeä: fraasit avautuvat kerta kerran jälkeen suuremmiksi, kunnes
            huippukohdan jälkeen taas sulkeutuvat rauhoittuvaan diminuendoon.
          "
        image={data.jaidenlahtokevat}
      />
      <Song
        title="Keskiyön aurinko"
        description="
            Keskiyön auringon taianomainen valo kuvataan jazz-tyylisin
            harmonioin ja pehmein pedaalisoinnein. Tässä kappaleessa
            harjoitellaan kevyttä kosketusta ja tunnelman luomista. Kappaleen
            keskiosan Disney-henkinen huippuhetki vaatii tukevaa sormenpäiden
            pitoa ja liikkeiden tarkkaa hallintaa."
        image={data.keskiyonAurinko}
      />
      <Song
        title="Sadonkorjuu"
        description="
            Kokoelman ainoa nelikätinen kappale kuvaa sadonkorjuun aikaa, joka
            on monen mielestä paras hetki vuoden kierrossa. Pohjoisessa
            sadonkorjuu tarkoittaa tyypillisesti luonnonmarjojen ja sienien
            poimimista, ja tässä kappaleessa ollaankin iloisesti metsässä. Tämän
            kansanmusiikkityylisen kappaleen nopean rytmikäs takapotkupoljento
            ja napakat staccatot kuvaavat poron letkeää askellusta maantiellä.
            Yhteissoittokappaleen haaste on nopeat crescendot ja diminuendot ja
            tarkka yhteinen koordinatio sekä primo-soittajan ketterä
            artikulaatio.
          "
        image={data.sadonkorjuu}
      />
      <Song
        title="Ruska"
        description="
            Yksi kokoelman hittikappaleista on Ruska, joka on oikeastaan teema
            ja muunnelmat miniatyyrimuodossa. Olen kuullut sanottavan, että
            d-molli on kaikista surullisin sävellaji, ja omassa musiikillisessa
            väripaletissani se on punainen, joten mielestäni se kuvaa ruskan
            värejä parhaiten. Kappaleessa voi kuulla haikeutta menneestä kesästä
            ja syksyn lehtien havinaa tuulessa. Vasemman käden laskeva
            terssisäestys pysyy samana läpi koko kappaleen, ja oikealla kädellä
            vuorottelevat erilaiset sorminäppäryyttä vaativat elementit:
            trillit, tremolot ja asteikkokuvioinnit. Kappale vaatii pedaalin
            osalta tarkkaa kuuntelua, ja ei siksi sovi välttämättä aivan
            ensimmäiseksi pedaalikappaleeksi.
          "
        image={data.ruska}
      />
      <Song
        title="Ensilumi"
        description="
            Värikkään ruskan jälkeen tulee pimeä ja kylmä vuodenaika, jolloin
            odotetaan ensilunta. Lapissa lumi sataa loka-marraskuussa.
            Ensilumi-kappaleen synkkä Halloween-tunnelma kuvaa aluksi hiljalleen
            mustaan maahan satavia lumihiutaleita. Oikean käden hiljaisena
            alkava pahaenteinen riffi jatkuu läpi kappaleen. Lumipyry yltyy
            ankaraksi ja lopuksi taas hiipuu. Kappale on yksi pitkä
            crescendo-diminuendo, jossa vasemmalla kädellä on tärkeä rooli
            musiikillisen kaaren rakentajana. Kahdeksan vuodenajan sykli päättyy
            samantyylisessä tunnelmassa, mistä alkoikin.
          "
        image={data.ensilumi}
      />
      <Song
        title="Sumuinen saari"
        description="
            Sumuisella saarella on salaperäistä tunnelmaa. Tässä kuulokuvaansa
            helpommassa kappaleessa harjoitellaan muun muassa murtosointuja ja
            käsien ristiinviemistä sekä pedaalinkäyttöä. Kappaleen keskiosan
            pysähtyvä ja eksynyt tunnelma on kuin ilmassa leijuvia
            kysymysmerkkejä. Lopetuksessa on tärkeä pedaaliefekti: viimeinen
            d-mollisointu paljastetaan ”sumusta” nostamalla pedaali
            mahdollisimman hitaasti.
          "
        image={data.sumuinenSaari}
      />
      <Song
        title="Salaiset vuoret"
        description="
            Yksi kokoelman omista suosikeistani on Salaiset vuoret. Sitä
            harjoitellessa on hyvä huomata vasemman käden hyvä asento:
            säestyskuvio onnistuu kauniisti ja tasaisesti kun soitetaan aivan
            peukalon kärjellä ja kannatellaan kättä ”korkeassa” asennossa.
            Litteällä kädellä säestyksestä tulee hankala, mutta pyöreä käden
            asento auttaa. Oikean käden terssimelodia on yksinkertainen, mutta
            vaatii sekin pariäänten hyvää hallintaa. B-osassa käsivarret
            vapautuvat tuottamaan runsaampaa sointia ja koko kehon joustavuus on
            tärkeää. Tämä on hyvä harjoitus vaikkapa erityisen staattisesti
            istuvalle tai jännittyneelle soittajalle. Kappaleen nimi antaa myös
            pohjan tulkinnalliselle keskustelulle: miksi vuoret ovat salaiset?
            Onko siellä ennen löytämättömiä kasveja tai hyönteisiä, salainen
            aarre tai kenties taikametsä?
          "
        image={data.salaisetVuoret}
      />
      <Song
        title="Revontulitaivas"
        description="
            Vihkon viimeinen sävellys, Revontulitaivas, vaatii soittajalta
            hieman virtuoosiasennetta ja rohkeutta, vaikka onkin lopulta melko
            yksinkertainen. Sen helisevät kuudestoistaosakuviot alkavat hitaasti
            ja hiljaa, kuin taivaalla ensin vain hieman vihertävät revontulet,
            mutta kiihtyvät ja voimistuvat sitten täyteen loistoonsa, kunnes
            sammuvat taas uudestaan. Keskiosan kvartit ja matalat oktaavit
            kuvaavat kylmää ja mustaa yötaivasta. Räiskyvien revontulien jälkeen
            viimeinen basso-oktaavi soitetaan mykkänä ennen pedaalin
            häivyttämistä - jäljelle jää vain tumma taivas revontulien
            hiipuessa.
          "
        image={data.revontulitaivas}
      />
    </Container>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    kahdeksanVuodenaikaa: file(
      relativePath: { eq: "kahdeksan-vuodenaikaa.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kaamos: file(relativePath: { eq: "kaamos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pakkastalvi: file(relativePath: { eq: "pakkastalvi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hankikanto: file(relativePath: { eq: "hankikanto.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jaidenlahtokevat: file(relativePath: { eq: "jaidenlahtokevat.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ruska: file(relativePath: { eq: "ruska.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    revontulitaivas: file(relativePath: { eq: "revontulitaivas.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sadonkorjuu: file(relativePath: { eq: "sadonkorjuu.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ensilumi: file(relativePath: { eq: "ensilumi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    keskiyonAurinko: file(relativePath: { eq: "keskiyon-aurinko.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    salaisetVuoret: file(relativePath: { eq: "salaiset-vuoret.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sumuinenSaari: file(relativePath: { eq: "sumuinen-saari.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    keskiyonAurinko: file(relativePath: { eq: "keskiyon-aurinko.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
